import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClaimService {
    claimId: any; 
    isClaimUpdate: boolean;
    //claimsApiBaseUrl = 'http://10.10.11.112/ClaimsAPI/api/';
    //claimsApiBaseUrl = 'http://localhost:82/api/';
    //claimsApiBaseUrl = 'https://localhost:44305/api/';
    claimsApiBaseUrl = 'https://fnolapiuat.tmhcc.com/api/';
    //claimsApiBaseUrl = 'http://204.8.178.24/ClaimsAPI/api/';

    constructor(private http: HttpClient) {
        
    }

    getScreen() {
        return screen;
    }

    getScreens() {
        if (sessionStorage.getItem('claimRefNum') != null && sessionStorage.getItem('claimRefNum').length > 0) {
            return this.http.get(this.claimsApiBaseUrl + 'screens/' + sessionStorage.getItem('claimRefNum'));
        }
        else if (this.claimId == undefined) {
            return this.http.get(this.claimsApiBaseUrl + 'screens/0');
        }
        return this.http.get(this.claimsApiBaseUrl + 'screens/' + this.claimId);
    }

    saveStep(step, recaptchaToken) {
        let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post(this.claimsApiBaseUrl + 'screens', { stepFields: step, recaptchaToken: recaptchaToken }, options);
    }

    auth(step, recaptchaToken) {
        let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post(this.claimsApiBaseUrl + 'auth', { stepFields: step, recaptchaToken: recaptchaToken }, options);
    }

    validate(claimId, zipCode, recaptchaToken, claimRefNum) {
        let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post(this.claimsApiBaseUrl + 'validate', { 'claimId': claimId, 'zipCode': zipCode, recaptchaToken: recaptchaToken, claimReferenceNumber: claimRefNum }, options);
    }

    submitClaim(notificationEmails, isClaimUpdate) {
        let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post(this.claimsApiBaseUrl + 'submit', { 'claimId': this.claimId === undefined ? '0' : this.claimId, 'notificationEmails': notificationEmails, 'isClaimUpdate': isClaimUpdate }, options);
    }

    deleteEntity(entityId, entityName) {
        let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        return this.http.post(this.claimsApiBaseUrl + 'deleteentity', { 'entityId': entityId, 'entityName': entityName}, options);
    }

    logMessage(message) {
        let token = sessionStorage.getItem('token');
        let bearer = 'Bearer ' + token;
        let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json',
                        'Authorization': bearer }) };
        return this.http.post(this.claimsApiBaseUrl + 'logs', {'body': message}, options);
    }

    getStates(){
        let states = [{name:"Alabama",abbreviation:"AL"},{name:"Alaska",abbreviation:"AK"},{name:"Arizona",abbreviation:"AZ"},{name:"Arkansas",abbreviation:"AR"},{name:"California",abbreviation:"CA"},{name:"Colorado",abbreviation:"CO"},{name:"Connecticut",abbreviation:"CT"},{name:"Delaware",abbreviation:"DE"},{name:"District of Columbia",abbreviation:"DC"},{name:"Florida",abbreviation:"FL"},{name:"Georgia",abbreviation:"GA"},{name:"Hawaii",abbreviation:"HI"},{name:"Idaho",abbreviation:"ID"},{name:"Illinois",abbreviation:"IL"},{name:"Indiana",abbreviation:"IN"},{name:"Iowa",abbreviation:"IA"},{name:"Kansas",abbreviation:"KS"},{name:"Kentucky",abbreviation:"KY"},{name:"Louisiana",abbreviation:"LA"},{name:"Maine",abbreviation:"ME"},{name:"Maryland",abbreviation:"MD"},{name:"Massachusetts",abbreviation:"MA"},{name:"Michigan",abbreviation:"MI"},{name:"Minnesota",abbreviation:"MN"},{name:"Mississippi",abbreviation:"MS"},{name:"Missouri",abbreviation:"MO"},{name:"Montana",abbreviation:"MT"},{name:"Nebraska",abbreviation:"NE"},{name:"Nevada",abbreviation:"NV"},{name:"New Hampshire",abbreviation:"NH"},{name:"New Jersey",abbreviation:"NJ"},{name:"New Mexico",abbreviation:"NM"},{name:"New York",abbreviation:"NY"},{name:"North Carolina",abbreviation:"NC"},{name:"North Dakota",abbreviation:"ND"},{name:"Ohio",abbreviation:"OH"},{name:"Oklahoma",abbreviation:"OK"},{name:"Oregon",abbreviation:"OR"},{name:"Pennsylvania",abbreviation:"PA"},{name:"Rhode Island",abbreviation:"RI"},{name:"South Carolina",abbreviation:"SC"},{name:"South Dakota",abbreviation:"SD"},{name:"Tennessee",abbreviation:"TN"},{name:"Texas",abbreviation:"TX"},{name:"Utah",abbreviation:"UT"},{name:"Vermont",abbreviation:"VT"},{name:"Virginia",abbreviation:"VA"},{name:"Washington",abbreviation:"WA"},{name:"West Virginia",abbreviation:"WV"},{name:"Wisconsin",abbreviation:"WI"},{name:"Wyoming",abbreviation:"WY"}];
        return states;
    }
}